// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  production: false,
  name: 'Development',
  firebase: {
    apiKey: "AIzaSyAd8SLIbtul66t_z2dBJGvD_g_cMteojr4",
    authDomain: "juma-entregas-dev.firebaseapp.com",
    databaseURL: "https://juma-entregas-dev.firebaseio.com",
    projectId: "juma-entregas-dev",
    storageBucket: "juma-entregas-dev.appspot.com",
    messagingSenderId: "1007042713279",
    appId: "1:1007042713279:web:7ffaee92ebbc067a4b180a",
    functons: "https://southamerica-east1-juma-entregas-dev.cloudfunctions.net/api"
  },
  socketUrl: 'https://boaztech-delivery-tracker.herokuapp.com'
};
